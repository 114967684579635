import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import { scaleLinear } from 'd3-scale';

const whiteTheme = {
	text: {
		fontSize: 16,
		fill: '#fffc',
		outlineWidth: 0,
		outlineColor: 'transparent',
	},
	axis: {
		domain: {
			line: {
				stroke: '#fffc',
				strokeWidth: 1,
			},
		},
		legend: {
			text: {
				fontSize: 12,
				fill: '#fffc',
				outlineWidth: 0,
				outlineColor: 'transparent',
			},
		},
		ticks: {
			line: {
				stroke: '#fffc',
				strokeWidth: 1,
			},
			text: {
				fontSize: 11,
				fill: '#fffc',
				outlineWidth: 0,
				outlineColor: 'transparent',
			},
		},
	},
	grid: {
		line: {
			stroke: '#fffc',
			strokeWidth: 1,
		},
	},
	legends: {
		title: {
			text: {
				fontSize: 11,
				fill: '#fffc',
				outlineWidth: 0,
				outlineColor: 'transparent',
			},
		},
		text: {
			fontSize: 11,
			fill: '#fffc',
			outlineWidth: 0,
			outlineColor: 'transparent',
		},
		ticks: {
			line: {},
			text: {
				fontSize: 10,
				fill: '#fffc',
				outlineWidth: 0,
				outlineColor: 'transparent',
			},
		},
	},
	annotations: {
		text: {
			fontSize: 13,
			fill: '#fffc',
			outlineWidth: 2,
			outlineColor: '#fffc',
			outlineOpacity: 1,
		},
		link: {
			stroke: '#fffc',
			strokeWidth: 1,
			outlineWidth: 2,
			outlineColor: '#fffc',
			outlineOpacity: 1,
		},
		outline: {
			stroke: '#fffc',
			strokeWidth: 2,
			outlineWidth: 2,
			outlineColor: '#fffc',
			outlineOpacity: 1,
		},
		symbol: {
			fill: '#fffc',
			outlineWidth: 2,
			outlineColor: '#fffc',
			outlineOpacity: 1,
		},
	},
};

const LineChart = ({ data }) => {
	return (
		<ResponsiveLine
			data={data}
			margin={{ top: 10, right: 10, bottom: 70, left: 60 }}
			xScale={{ type: 'point' }}
			yScale={{
				type: 'linear',
				min: 0,
				max: 'auto',
				stacked: false,
				reverse: false,
			}}
			lineWidth={4}
			yFormat=' >-.2f'
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 0,
				tickPadding: 5,
				tickRotation: -45,

				legendOffset: -200,
				legendPosition: 'middle',
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 15,
				tickRotation: 0,

				legendOffset: -50,
				legendPosition: 'middle',
			}}
			colors={['#abdbe3', '#3a8991', '#154c79', '#22755a', '#2e1757']}
			theme={whiteTheme}
			enableGridX={false}
			enableGridY={true}
			gridYValues={[0]}
			enablePoints={false}
			pointSize={10}
			pointBorderWidth={2}
			pointLabelYOffset={-12}
			useMesh={true}
			legends={[
				{
					anchor: 'bottom',
					direction: 'row',
					justify: false,
					translateX: 0,
					translateY: 60,
					itemsSpacing: 0,
					itemDirection: 'left-to-right',
					itemWidth: 80,
					itemHeight: 20,
					itemOpacity: 0.75,
					symbolSize: 12,
					symbolShape: 'circle',
					symbolBorderColor: 'rgba(0, 0, 0, .5)',
					effects: [
						{
							on: 'hover',
							style: {
								itemBackground: 'rgba(0, 0, 0, .03)',
								itemOpacity: 1,
							},
						},
					],
				},
			]}
		/>
	);
};
const BarChart = ({ data, barChartKeys }) => {
	return (
		<>
			<ResponsiveBar
				data={data}
				keys={barChartKeys}
				indexBy='Month'
				groupMode='grouped'
				margin={{ top: 20, right: 90, bottom: 30, left: 60 }}
				padding={0.3}
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				colors={['#abdbe3', '#3a8991', '#154c79', '#22755a', '#2e1757']} //
				theme={whiteTheme}
				defs={[
					{
						id: 'dots',
						type: 'patternDots',
						background: 'inherit',
						color: '#fffc',
						size: 4,
						padding: 1,
						stagger: true,
					},
					{
						id: 'lines',
						type: 'patternLines',
						background: 'inherit',
						color: '#fffc',
						rotation: -45,
						lineWidth: 6,
						spacing: 10,
					},
				]}
				borderColor={{
					from: 'color',
					modifiers: [['darker', 1.6]],
				}}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: -45,

					legendPosition: 'middle',
					legendOffset: 0,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,

					legendPosition: 'bottom',
					legendOffset: 0,
				}}
				enableGridX={false}
				enableGridY={true}
				gridYValues={[0]}
				enableLabel={false}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{
					from: 'color',
					modifiers: [['darker', 1.6]],
				}}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'bottom-right',
						direction: 'column',
						justify: false,
						translateX: 120,
						translateY: 0,
						itemsSpacing: 2,
						itemWidth: 100,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 20,
					},
				]}
			/>
		</>
	);
};

const WeatherChart = ({ data }) => {
	const xTicks = ['08:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00'];

	const colorScale = scaleLinear()
        .domain([-10, 26])
        .range(['#013a63', '#a9d6e5']);

	return (
		<ResponsiveBar
			data={data}
			keys={['Energy']}
			indexBy='Month'
			margin={{ top: 10, right: 0, bottom: 40, left: 20 }}
			padding={0.3}
			valueScale={{ type: 'linear' }}
			yScale={{
				type: 'linear',
				min: 'auto',
				max: 'auto',
			}}
			indexScale={{ type: 'band', round: true }}
			theme={whiteTheme}
			colors={({ value: Energy }) => colorScale(Energy)}
			defs={[
				{
					id: 'dots',
					type: 'patternDots',
					background: 'inherit',
					color: '#fffc',
					size: 4,
					padding: 1,
					stagger: true,
				},
				{
					id: 'lines',
					type: 'patternLines',
					background: 'inherit',
					color: '#fffc',
					rotation: -45,
					lineWidth: 6,
					spacing: 10,
				},
			]}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: -45,
				legendPosition: 'middle',
				tickValues: xTicks,
				legendOffset: 0,
			}}
			axisLeft={{
				tickSize: 2,
				tickPadding: 2,
				tickRotation: 0,
				legendPosition: 'middle',
				legendOffset: 0,
				tickValues: 4,
			}}
			enableGridX={false}
			enableGridY={true}
			gridYValues={[0]}
			enableLabel={false}
			labelSkipWidth={12}
			labelSkipHeight={12}
			labelTextColor={{
				from: 'color',
				modifiers: [['darker', 1.6]],
			}}
			legends={[]}
		/>
	);
};
export { LineChart, BarChart, WeatherChart };

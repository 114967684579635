import React, { useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { MINUTES_PER_MONTH, MINUTES_PER_YEAR } from '../../../constants';

const useStyles = makeStyles(() => ({
	switchgroup: {
		color: 'white',
		lineHeight: '25px',
		fontSize: '12px',
		textTransform: 'uppercase',
		fontFamily:
			'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica Neue, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
	},
	switchPrevious: {
		position: 'absolute',
		left: '55%',
		top: '25%',
	},
	switchPreviousYear: {
		position: 'absolute',
		left: '74%',
		top: '25%',
	},
}));

export default function SwitchLabels({
	handlePreviousTimeToggle,
	handleToggleLastYearData,
	previousTimeToggle,
	monthLastYearBool,
	currentDatetimeRange,
}) {
	const classes = useStyles();

	const [comparePrevious, setComparePrevious] = useState(Boolean(previousTimeToggle));

	const [monthComparePreviousYear, setMonthComparePreviousYear] = useState(monthLastYearBool);

	const { t } = useTranslation();

	const handleComparePreviousChange = event => {
		setComparePrevious(event.target.checked);
		handlePreviousTimeToggle();
	};

	const handleMonthCompareModeChange = event => {
		setMonthComparePreviousYear(event.target.checked);
		handleToggleLastYearData();
	};

	return (
		<FormGroup row className={classes.switchgroup}>
			{(currentDatetimeRange.offset === MINUTES_PER_YEAR || currentDatetimeRange.offset === MINUTES_PER_MONTH) && (
				<FormControlLabel
					className={classes.switchPrevious}
					control={
						<Switch
							checked={comparePrevious}
							onChange={handleComparePreviousChange}
							name='comparePrevious'
							color={'default'}
							size='small'
						/>
					}
					label={<span style={{ fontSize: '12px' }}>{t('sensorGraph.compare')}</span>}
					labelPlacement='left'
					data-cy='compare-time'
				/>
			)}
			{comparePrevious && currentDatetimeRange.offset === MINUTES_PER_MONTH ? (
				<FormControlLabel
					className={classes.switchPreviousYear}
					control={
						<Switch
							checked={monthComparePreviousYear}
							onChange={handleMonthCompareModeChange}
							name='monthCompareMode'
							color={'default'}
							size='small'
						/>
					}
					label={
						monthComparePreviousYear ? (
							<span style={{ fontSize: '12px' }}>{t('modalSettings.previousYear')}</span>
						) : (
							<span style={{ fontSize: '12px' }}>{t('modalSettings.currentYear')}</span>
						)
					}
					labelPlacement='left'
					data-cy='latest-previous'
				/>
			) : null}
		</FormGroup>
	);
}

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog } from '@material-ui/core/';
import { SensorSelectorTable } from '../Widget/SensorSelectorTable';
import * as colors from '../../../colors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
	root: {
		position: 'absolute',
		left: '3%',
		fontSize: '10px',
		top: '12%',
	},
}));
export default function ContainedButtons({ selectedProperties, graphSensors, changeSensorsCallback }) {
	const classes = useStyles();
	const [openPopup, setOpenPopup] = useState(false);

	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<Button
				variant='contained'
				style={{ backgroundColor: colors.primary, color: 'white' }}
				onClick={() => setOpenPopup(true)}
				data-cy='modal-sensor-button'
			>
				{t('constants.sensors')}
			</Button>
			<Dialog open={openPopup} maxWidth={false} PaperComponent={props => <>{props.children}</>} data-cy='sensor-selector-dialog'>
				<SensorSelectorTable
					isOpen={openPopup}
					selectedProperties={selectedProperties}
					graphSensors={graphSensors}
					changeSensorsCallback={changeSensorsCallback}
					closeCallback={() => setOpenPopup(false)}
				/>
			</Dialog>
		</div>
	);
}

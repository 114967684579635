import 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../../colors';

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: 'transparent',
		width: '50%',
		height: '20%',
		color: 'white',
	},
	textField: {
		marginLeft: theme.spacing(1.1),
		marginRight: theme.spacing(1.1),
		width: 'fit-content',
		color: 'white',
	},
	customDivider: {
		height: '86%',
		width: '1px',
		backgroundColor: 'white',
		marginTop: '1%',
		marginLeft: '50%',
		position: 'absolute',
	},
}));

export default function MaterialUIPickers({ dataIndex, dataIndexCallback, constraintTimestamp }) {
	const datePickerStyles = {
		color: 'white',
	};

	const classes = useStyles();
	const { t } = useTranslation();

	const handleStartDateChange = date => {
		let startIndex = date.getTime();
		let endIndex = dataIndex[1];
		if (startIndex > endIndex) {
			[startIndex, endIndex] = [endIndex, startIndex];
		}
		dataIndexCallback([startIndex, endIndex]);
	};

	const handleEndDateChange = date => {
		let endIndex = date.getTime();
		let startIndex = dataIndex[0];
		if (startIndex > endIndex) {
			[startIndex, endIndex] = [endIndex, startIndex];
		}
		dataIndexCallback([startIndex, endIndex]);
	};

	return (
		<>
			<div
				style={{
					width: '26%',
					left: '66%',
					position: 'absolute',

					top: '8%',

					backgroundColor: colors.primary,
					border: '1px solid lightgrey',
					borderRadius: '5px',
					color: 'white',
					height: '6%',
				}}
			>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<Grid container className={classes.container} data-cy='date-picker'>
						<KeyboardDatePicker
							margin='none'
							id='date-picker-dialog-start'
							label={t('widgetPopoutMenu.datePickerStartDate')}
							format='yyyy/MM/dd'
							value={dataIndex[0]}
							onChange={handleStartDateChange}
							minDate={constraintTimestamp[0]}
							maxDate={constraintTimestamp[1]}
							KeyboardButtonProps={{
								'aria-label': 'change start date',
								style: datePickerStyles,
							}}
							InputProps={{
								style: datePickerStyles,
							}}
							InputLabelProps={{
								style: { ...datePickerStyles, marginTop: '5px' }, // Adjust the marginTop as needed
							}}
							className={classes.textField}
							data-cy='start-date'
						/>
						<div className={classes.customDivider} />

						<KeyboardDatePicker
							style={{
								left: '50%',
								position: 'absolute',
							}}
							margin='none'
							id='date-picker-dialog-end'
							label={t('widgetPopoutMenu.datePickerEndDate')}
							format='yyyy/MM/dd'
							value={dataIndex[1]}
							onChange={handleEndDateChange}
							minDate={constraintTimestamp[0]}
							maxDate={constraintTimestamp[1]}
							KeyboardButtonProps={{
								'aria-label': 'change start date',
								style: datePickerStyles,
							}}
							InputProps={{
								style: datePickerStyles,
							}}
							InputLabelProps={{
								style: { ...datePickerStyles, marginTop: '5px' }, // Adjust the marginTop as needed
							}}
							className={classes.textField}
							data-cy='end-date'
						/>
					</Grid>
				</MuiPickersUtilsProvider>
			</div>
		</>
	);
}

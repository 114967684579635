import React, { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { GET_AI_CONTROL } from '../../utility-functions/gqlQueries';
import { CircularProgress, FormControl, Tab, Tabs, Select, MenuItem } from '@material-ui/core';
import AiOverview from './AiOverview';
import { useTranslation } from 'react-i18next';

function CustomTabPanel({ children, value, index, ...props }) {
    return (
        <div role='tabpanel' hidden={value !== index} id={`system-tabpanel-${index}`} aria-labelledby={`system-tab-${index}`} {...props}>
            {value === index && children}
        </div>
    );
}

function AiFolderWrapper({ property }) {
    const { t } = useTranslation();
    const [aiControls, setAiControls] = useState();
    const [tabIndex, setTabIndex] = useState(0);
    const [tabs, setTabs] = useState([]);
    const [interval, setInterval] = useState('week'); // State for selected interval
    console.log('now runs GET_AI_CONTROL IN WRAPPER');
    const { loading, error } = useQuery(GET_AI_CONTROL, {
        variables: { filter: { locationid: [String(property)] } },
        onCompleted: ({ getAiControls: data }) => {
            const dataOrderedBySystemId = data.reduce((acc, control) => {
                if (control.system == null) return acc;
                const system = control.system;
                if (acc[system]) acc[system].push(control);
                else acc[system] = [control];
                return acc;
            }, {});
            const correctOrderedIds = Object.keys(dataOrderedBySystemId).sort();
            setAiControls(correctOrderedIds.map(id => dataOrderedBySystemId[id]));
            setTabs(correctOrderedIds.map(id => ({ id: id, label: `${t('generic.controlSystem')}: ${id}` })));
        },
    });

    if (loading)
        return (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '5rem' }}>
                <CircularProgress />
            </div>
        );

    if (error) return <div>Error: {error.message}</div>;

    return (
        <div>
            <Tabs value={tabIndex} onChange={(event, index) => setTabIndex(index)}>
                {tabs.map((tab, i) => (
                    <Tab key={tab.id} label={tab.label} id={`system-tab-${i}`} aria-controls={`system-tabpanel-${i}`} />
                ))}
            </Tabs>
            {tabs.map((tab, i) => (
                <CustomTabPanel key={tab.id} value={tabIndex} index={i}>
                    <AiOverview aiControls={aiControls[i]} interval={interval} systemId={tab.id} />
                </CustomTabPanel>
            ))}
        </div>
    );
}

export default AiFolderWrapper;

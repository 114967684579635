import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { getStateVariables, STORE } from '../redux/selectors';
import MeterCard from './MeterCard';
import SensorGroupCard from './SensorGroupCard';

const GET_AI_CONTROL = gql`
  query GetAiControls($filter: AiControlFilter) {
    getAiControls(filter: $filter) {
      index
      locationid
      sensorid
      sensortype
    }
  }
`;

/**
 * Responsible for rendering sensor and sensor-group cards
 */
function IndoorSensor(props) {
	const [query, setQuery] = useState({ sensorid: undefined, sensorgroupid: undefined });

	useTranslation();

	const AiControlQuery = useQuery(GET_AI_CONTROL, {
		variables: {
			filter: {
				locationid: [props.currentProperty],
			},
		},
	});

	const filteredAiControl = AiControlQuery.data?.getAiControls.filter(control => control.sensortype === 'indoortemp' && control.locationid === (props.currentProperty).toString()) || [];
	const _propertySensors = props.sensors.filter(sen => sen.locationid === props.currentProperty || sen.locationid !== props.currentProperty);// todo: after adding acutal sensors to the database, remove the second condition
	const matchedSensors = _propertySensors.filter(propertySensor =>
		filteredAiControl.some(aiControl => aiControl.sensorid === propertySensor.sensorid)
	);
	const sensorGroups = props.sensorGroups
		.filter(grp => grp.locationid === props.currentProperty || grp.locationid !== props.currentProperty) // todo: after adding acutal sensors to the database, remove the second condition
		.map(grp => ({ ...grp, sensors: matchedSensors.filter(sen => sen.sensorgroupid === Number(grp.sensorgroupid)) }));
	const _sensorGroupIds = sensorGroups.map(grp => Number(grp.sensorgroupid));
	const standaloneSensors = matchedSensors.filter(sen => !sen.sensorgroupid || !_sensorGroupIds.includes(sen.sensorgroupid));

	useEffect(() => {
		const querySensorId = new URLSearchParams(window.location.search).get('sensor');
		if (querySensorId) {
			const querySensor = props.sensors.find(sen => sen.sensorid === querySensorId);
			if (querySensor) setQuery({ sensorid: querySensor.sensorid, sensorgroupid: String(querySensor.sensorgroupid) });
		}
		// eslint-disable-next-line
	}, [window.location.search]);

	return (
		<div >
			<div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>

				{sensorGroups
					.sort((a, b) => {
						// Custom hard-coded sorting
						if (a.name === 'Energi') return -1;
						else if (b.name === 'Energi') return 1;
						else if (a.name === 'Undercentral') return -1;
						else if (b.name === 'Undercentral') return 1;
						else if (a.name?.startsWith('Sensor') && !b.name?.startsWith('Sensor')) return -1;
						else if (b.name?.startsWith('Sensor') && !a.name?.startsWith('Sensor')) return 1;
						else return a.name < b.name ? -1 : 1;
					})
					.map(sensorGroup => {
						if (sensorGroup.sensors.length > 0) {
							return (
								<SensorGroupCard
									sensorGroup={sensorGroup}
									selectedSensorId={sensorGroup.sensorgroupid === query.sensorgroupid ? query.sensorid : undefined}
									waitForAlarm = {false}
									key={'sg-' + sensorGroup.sensorgroupid}
								/>
							)
						} else {
							return null;
						}
					})}

				{standaloneSensors
					.sort((a, b) => (a.name < b.name ? -1 : 1))
					.map(sensor => (
						<MeterCard sensor={sensor} isSelected={sensor.sensorid === query.sensorid} key={sensor.sensorid} />
					))}

			</div>
		</div>
	);
}

export default connect(getStateVariables(STORE.currentProperty, STORE.sensors, STORE.sensorGroups))(IndoorSensor);

import React, { useState, useEffect } from 'react';
import { Paper, IconButton, Dialog } from '@material-ui/core';
import {
	SpeakerNotesOutlined as NotificationIcon,
	SpeakerNotesOffOutlined as NotificationOffIcon,
	SettingsOutlined as SettingsIcon,
	ThreeDRotation as ThreeDIcon,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { format as formatDate } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { getStateVariables, STORE } from '../redux/selectors';
import { setTab, updateSensors, updateUserInfo } from '../redux/actionCreators';
import { getFormattedNumber, drawTruncatedStr, isValueInsideThresholds } from '../utility-functions';
import ToggleButton from './ToggleButton';
import AlarmEditor from './AlarmEditor';
import { SensorGraph } from './SensorGraph';
import DialogWrapper from './DialogWrapper';
import ThreeSixtyView from './ThreeSixtyView';

const SET_SENSOR_SUBSCRIPTIONS = gql`
	mutation ($id: String!, $add: Boolean!, $sensorSubscriptions: [Int!]!) {
		addOrRemoveKeycloakUserAttributes(id: $id, add: $add, sensorSubscriptions: $sensorSubscriptions) {
			sensorSubscriptions
		}
	}
`;
const GET_DIGITALTWIN_TAGS = gql`
	query ($filter: DigitalTwinTagFilter!) {
		getDigitalTwinTags(filter: $filter) {
			digitaltwintagid
			digitaltwinid
		}
	}
`;
const GET_DIGITALTWIN_PERSPECTIVES = gql`
	query ($filter: DigitalTwinPerspectiveFilter!) {
		getDigitalTwinPerspectives(filter: $filter) {
			digitaltwinperspectiveid
			digitaltwinid
			sweep
			rotationx
			rotationy
			zoom
		}
	}
`;

const ICON_STYLE = { width: '1.1rem', height: '1.1rem' };
const ICON_BUTTON_STYLE = { width: '1.75rem', height: '1.75rem' };

/**
 * Draws a card with info about a sensor
 * @param {object} sensor : To draw
 * @param {boolean} isSelected : Whether the sensor is selected and a history-popup should open
 */
function MeterCard(props) {
	const [showAlarmEditor, setShowAlarmEditor] = useState(false);
	const [showSensorGraph, setShowSensorGraph] = useState(false);
	const [show360Popup, setShow360Popup] = useState(false);
	const [digitalTwinSelection, setDigitalTwinSelection] = useState(); // Format: {digitalTwin: object, tagId: number, perspective: object}

	const [setSensorSubscriptions] = useMutation(SET_SENSOR_SUBSCRIPTIONS, {
		onCompleted: ({ addOrRemoveKeycloakUserAttributes }) => props.updateUserInfo(addOrRemoveKeycloakUserAttributes),
		onError: e => console.log(e),
	});
	const [getDigitalTwinTags] = useLazyQuery(GET_DIGITALTWIN_TAGS, {
		onCompleted: ({ getDigitalTwinTags }) => {
			if (!getDigitalTwinTags[0]) return;
			setShow360Popup(true);
			setDigitalTwinSelection({
				digitalTwin: props.digitalTwins.find(dt => dt.digitaltwinid === getDigitalTwinTags[0].digitaltwinid),
				tagId: getDigitalTwinTags[0].digitaltwintagid,
			});
		},
		fetchPolicy: 'no-cache',
	});
	const [getDigitalTwinPerspectives] = useLazyQuery(GET_DIGITALTWIN_PERSPECTIVES, {
		onCompleted: ({ getDigitalTwinPerspectives }) => {
			if (!getDigitalTwinPerspectives[0]) return;
			setShow360Popup(true);
			setDigitalTwinSelection({
				digitalTwin: props.digitalTwins.find(dt => dt.digitaltwinid === getDigitalTwinPerspectives[0].digitaltwinid),
				perspective: getDigitalTwinPerspectives[0],
			});
		},
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (props.isSelected) {
			setShowSensorGraph(true);
			setTimeout(() => {
				const selectedSenCard = document.getElementById(props.sensor.sensorid);
				if (selectedSenCard) selectedSenCard.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}, 600); // Delay required for scrolling to work
		}
		// eslint-disable-next-line
	}, [props.isSelected]);

	const meterBackground = {
		display: 'flex',
		position: 'relative',
		justifyContent: 'center',
		alignItems: 'center',
		width: '5.3rem',
		borderRadius: '0.4rem 0px 0px 0.4rem',
		cursor: 'pointer',
	};
	const normalMeterBackground = {
		...meterBackground,
		...{ backgroundImage: 'linear-gradient(150deg, rgba(105, 186, 58, 1) 0%, rgba(56, 145, 18, 1) 100%' },
	};
	const alarmMeterBackground = {
		...meterBackground,
		...{ backgroundImage: 'linear-gradient(150deg, rgba(255, 74, 81, 1) 0%, rgba(181, 0, 3, 1) 100%' },
	};

	const { t } = useTranslation();

	function toggleSensorSub(sensorId) {
		setSensorSubscriptions({
			variables: {
				id: props.userInfo.id,
				add: !(props.userInfo?.sensorSubscriptions || []).includes(Number(sensorId)),
				sensorSubscriptions: [Number(sensorId)],
			},
		});
	}

	return (
		<>
			<Paper
				elevation={2}
				id={props.sensor.sensorid}
				style={{
					display: 'flex',
					position: 'relative',
					margin: '0 0 1rem 1rem',
					width: '18.64rem',
					height: '5.3rem',
					borderRadius: '0.4rem',
					[props.isSelected && 'background']: '#ffb',
				}}
			>
				<Paper
					onClick={() => setShowSensorGraph(true)}
					elevation={0}
					style={props?.activeAlarm ? alarmMeterBackground : normalMeterBackground}
				>
					<div style={{ color: '#fffd', fontWeight: '500', filter: 'drop-shadow(0 0 4px #0006)', textAlign: 'center' }}>
						<div style={{ fontSize: '162%' }}>
							{typeof props.sensor.value === 'number' ? getFormattedNumber(props.sensor.value) : '-'}
						</div>
						<div style={{ fontSize: '90%', opacity: '0.7', margin: '-0.2rem 0 0 0.1rem' }}>{props.sensor.unit || ''}</div>
					</div>

					<IconButton
						onClick={e => {
							e.stopPropagation();
							if (props.sensor.digitaltwintagid)
								getDigitalTwinTags({
									variables: { filter: { digitaltwintagids: [props.sensor.digitaltwintagid] } },
								});
							else if (props.sensor.digitaltwinperspectiveid)
								getDigitalTwinPerspectives({
									variables: {
										filter: { digitaltwinperspectiveids: [props.sensor.digitaltwinperspectiveid] },
									},
								});
						}}
						size='small'
						style={{
							...ICON_BUTTON_STYLE,
							position: 'absolute',
							top: '-0.6rem',
							right: '-0.8rem',
							background: '#f2f2f2',
							boxShadow: '0 0 0.1rem #0008',
							visibility: props.sensor.digitaltwintagid || props.sensor.digitaltwinperspectiveid ? 'visible' : 'hidden',
						}}
					>
						<ThreeDIcon style={{ ...ICON_STYLE, color: '#555' }} />
					</IconButton>
				</Paper>

				<div style={{ display: 'inline-block' }}>
					<h3 style={{ margin: '0.5rem 0 0 0.8rem', fontSize: '110%' }}>{drawTruncatedStr(props.sensor.name, 21)}</h3>
					<p style={{ margin: '0.1rem 0 0 0.8rem', color: '#999', fontSize: '80%' }}>
						{t('meterCard.updated')}
						{props.sensor.timestamp &&
							//Date(pros.sensor.timestamp) does not work
							formatDate(
								typeof props.sensor.timestamp === 'string' ? Date.parse(props.sensor.timestamp) : props.sensor.timestamp,
								'yyyy-MM-dd HH:mm'
							)}
					</p>

					<div style={{ display: 'flex', margin: '0.3rem 0 0 0.8rem' }}>
						<div style={{ color: '#666' }}>{t('meterCard.thresholds')}</div>
						<div style={{ marginLeft: '0.8rem' }}>
							{typeof props.sensor.lowerthreshold === 'number' ? getFormattedNumber(props.sensor.lowerthreshold) : '-'} :{' '}
							{typeof props.sensor.upperthreshold === 'number' ? getFormattedNumber(props.sensor.upperthreshold) : '-'}
						</div>
					</div>
				</div>

				<ToggleButton
					isOn={props.userInfo.sensorSubscriptions?.includes(Number(props.sensor.sensorid))}
					callback={() => toggleSensorSub(props.sensor.sensorid)}
					onIconElement={<NotificationIcon style={{ ...ICON_STYLE, color: '#444' }} />}
					offIconElement={<NotificationOffIcon style={{ ...ICON_STYLE, color: '#bbb' }} />}
					style={{ padding: '0.4rem', position: 'absolute', top: '0.28rem', right: '0.28rem' }}
				/>
				<IconButton
					onClick={() => setShowAlarmEditor(true)}
					size='small'
					style={{
						...ICON_BUTTON_STYLE,
						position: 'absolute',
						bottom: '0.37rem',
						right: '0.37rem',
					}}
				>
					<SettingsIcon style={{ ...ICON_STYLE, color: '#555' }} />
				</IconButton>
			</Paper>

			<DialogWrapper
				dialogProps={{
					open: showSensorGraph,
					onClose: () => setShowSensorGraph(false),
				}}
			>
				<SensorGraph sensorInfo={props.sensor} showTitle isVisible={showSensorGraph} />
			</DialogWrapper>

			<AlarmEditor sensor={props.sensor} open={showAlarmEditor} onExit={() => setShowAlarmEditor(false)} />

			<Dialog
				open={show360Popup}
				onClose={() => setShow360Popup(false)}
				onExited={() => setDigitalTwinSelection()}
				PaperComponent={props => <>{props.children}</>}
			>
				<ThreeSixtyView digitalTwin={digitalTwinSelection?.digitalTwin} selection={digitalTwinSelection} />
			</Dialog>
		</>
	);
}

export default connect(getStateVariables(STORE.digitalTwins, STORE.currentProperty, STORE.userInfo), {
	setTab,
	updateSensors,
	updateUserInfo,
})(MeterCard);

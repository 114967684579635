import React, { useEffect, useState } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import { Trans } from 'react-i18next';
import { addMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { MINUTES_PER_MONTH, MINUTES_PER_YEAR, SECONDS_PER_MINUTE } from '../../../constants';

const MILLISECONDS_PER_SECOND = 1000;
const MILLISECONDS_PER_MINUTE = MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE;

const GET_SENSOR_DATA_AND_VIEW = gql`
	query ($sensorDataFilter: SensorDataFilter!, $sensorViewFilter: SensorViewFilter) {
		getSensorData(filter: $sensorDataFilter) {
			sensorid
			sensordata {
				x
				y
			}
		}
		getSensorView(filter: $sensorViewFilter) {
			sensorid
			sensorref
			name
			unit
			value
			classification
			locationid
			city
			street
			cadastral
			area
			timestamp
			classification
			minvalue
			maxvalue
			lowerthreshold
			upperthreshold
			sensorgroupid
			groupname
			multiplier
		}
	}
`;

const GET_SENSOR_DATA = gql`
	query ($filter: SensorDataFilter!) {
		getSensorData(filter: $filter) {
			sensorid
			sensordata {
				x
				y
			}
		}
	}
`;

function prettyPrintStreet(street) {
	const streetNameAndNumber = street.split(' ');
	if (streetNameAndNumber.length > 2 && streetNameAndNumber[0].length <= 6) {
		return streetNameAndNumber[0] + ` ${streetNameAndNumber[1].slice(0, 5)}... ${streetNameAndNumber[streetNameAndNumber.length - 1]}`;
	} else if (streetNameAndNumber.length >= 2) {
		return `${streetNameAndNumber[0].slice(0, 5)}... ${streetNameAndNumber[streetNameAndNumber.length - 1]}`;
	}
	return street;
}

function getIntervalFromDates(startDate, endDate, numberOfDatapoints) {
	return Math.floor((endDate - startDate) / (MILLISECONDS_PER_MINUTE * numberOfDatapoints));
}

function getIntervalType(widgetType, timeDiff) {
	if (widgetType === "LineChart"){
		if (timeDiff <= 7){
			return "raw";
		}
		else {
			return "mean";
		}
	}
	if (widgetType === "Speedometer") return "raw";
	if (widgetType === "BarChart") return "sum";
	if (widgetType === "PieChart") return "mean";
	console.error("Unknown widget type");
	return "mean";
}

const getpreviousFilter = (sensors, timeRange, monthLastYearBool, numberOfDatapoints, previousTimeToggle, widgetType) => {
	if (timeRange.offset !== null && previousTimeToggle) {
		let startLastYear = addMinutes(new Date(), -timeRange.offset); // detta blir 11 nov 2022
		let endLastYear = new Date(); // detta blir ju 11 nov 2023
		if (timeRange.offset === MINUTES_PER_MONTH) {
			endLastYear.setFullYear(endLastYear.getFullYear() - 1);
			startLastYear.setFullYear(startLastYear.getFullYear() - 1);
		} else if (timeRange.offset === MINUTES_PER_YEAR) {
			if (endLastYear.getMonth() === -1) {
				endLastYear.setMonth(11); // December
				endLastYear.setFullYear(endLastYear.getFullYear() - 1);
			}
			if (monthLastYearBool === true) {
				endLastYear.setFullYear(endLastYear.getFullYear() - 1);
				startLastYear.setFullYear(startLastYear.getFullYear() - 1);
			} else {
				startLastYear.setMonth(startLastYear.getMonth() - 1);
				endLastYear.setMonth(endLastYear.getMonth() - 1);
			}
		}
		endLastYear = endLastYear.toISOString();
		startLastYear = startLastYear.toISOString();
		const timeDiff = (endLastYear.valueOf() - startLastYear.valueOf()) / 86400000;
		const interval_type = getIntervalType(widgetType, timeDiff);
		const previousTimeFilter= {
			sensorids: sensors.map(({ sensorid }) => sensorid),
			timestamp_gte: startLastYear,
			timestamp_lte: endLastYear,
			timestamp_interval: Math.floor(timeRange.offset / numberOfDatapoints),
			interval_type: interval_type,
		};
		return { filter: previousTimeFilter };
	}
};

function getFilter(sensors, timeRange, numberOfDatapoints, widgetType, cache = true) {
	const sensorids = sensors.map(({ sensorid }) => sensorid);
	const timeDiff = (timeRange.endDate.valueOf() - timeRange.startDate.valueOf()) / 86400000;
	const interval_type = getIntervalType(widgetType, timeDiff);
	
	const sensorDataFilter = {
		sensorids: sensorids,
		timestamp_gte: timeRange.startDate.toISOString(),
		timestamp_lte: timeRange.endDate.toISOString(),
		timestamp_interval: timeRange.startDate
			? getIntervalFromDates(timeRange.startDate, timeRange.endDate, numberOfDatapoints)
			: undefined,
		interval_type: interval_type,
	};

	const sensorViewFilter = {
		sensorids: sensorids,
	};
	if (!cache && timeRange.offset) {
		sensorDataFilter.timestamp_gte = addMinutes(new Date(), -timeRange.offset).toISOString();
		sensorDataFilter.timestamp_lte = new Date().toISOString();
	}
	return {
		sensorDataFilter: sensorDataFilter,
		sensorViewFilter: sensorViewFilter,
	};
}

function SensorDataFetcher({
	timeRange,
	sensors,
	numberOfDatapoints,
	updateInterval,
	normalize,
	dataLoadedCallback,
	children,
	previousTimeToggle = false,
	monthLastYearBool = false,
	widgetType,
}) {
	const [chartData, setChartData] = useState([]);
	const [sensorMetaData, setSensorMetaData] = useState([]);

	const [intervalId, setIntervalId] = useState(null);
	const [sensorUnits, setSensorUnits] = useState([]);

	const { t } = useTranslation();
	const [callSensorDataQuery, { loading: dataLoading, error: dataError, data }] = useLazyQuery(GET_SENSOR_DATA_AND_VIEW);
	const [callDataPreviousQuery, { data: dataPrevious }] = useLazyQuery(GET_SENSOR_DATA);

	useEffect(() => {
		callSensorDataQuery({
			variables: getFilter(sensors, timeRange, numberOfDatapoints, widgetType),
		});

		if (previousTimeToggle) {
			callDataPreviousQuery({
				variables: getpreviousFilter(sensors, timeRange, monthLastYearBool, numberOfDatapoints, previousTimeToggle, widgetType),
			});
		}
		// eslint-disable-next-line
	}, [callSensorDataQuery, sensors, timeRange, numberOfDatapoints, previousTimeToggle, monthLastYearBool]);

	useEffect(() => {
		// Workaround since startPolling/stopPolling does not update filter
		// https://github.com/apollographql/apollo-client/issues/3053

		// TD, a new fetcher dedicated for Speedometer is needed
		if (updateInterval > 0 && timeRange.offset != null) {
			const newIntervalId = setInterval(() => {
				callSensorDataQuery({
					variables: getFilter(sensors, timeRange, numberOfDatapoints, widgetType, false),
				});
			}, updateInterval * MILLISECONDS_PER_SECOND);
			setIntervalId(newIntervalId);
			return () => {
				clearInterval(newIntervalId);
			};
		}
		// eslint-disable-next-line
	}, [updateInterval, timeRange]);

	// useEffect(() => {
	// 	// Workaround since startPolling/stopPolling does not update filter
	// 	// https://github.com/apollographql/apollo-client/issues/3053
	// 	if (widgetType === 'SpeedoMeter' && sensors.length > 0) {
	// 		clearInterval(intervalId);
	// 		const newIntervalId = setInterval(() => {
	// 			callSensorDataQuery({
	// 				variables: getFilter(sensors, timeRange, width, datapoints_per_width),
	// 			});
	// 		}, 20 * MILLISECONDS_PER_SECOND);
	// 		setIntervalId(newIntervalId);
	// 		return () => clearInterval(intervalId);
	// 	}
	// 	// eslint-disable-next-line
	// }, [widgetType, sensors]);

	useEffect(() => {
		formatLineChartData(data);
		// eslint-disable-next-line
	}, [data, dataPrevious, dataLoading, normalize]);

	if (dataError) {
		return (
			<p>
				<Trans i18nKey={'sensorComponent.fetchError'} values={{ error: dataError.message }} />
			</p>
		);
	}

	function formatLineChartData(data) {
		if (!data || dataLoading || dataError) {
			return;
		}

		const sensorData = data.getSensorData;
		const sensorView = data.getSensorView;

		if (!sensorData.length) {
			if (dataLoadedCallback) {
				dataLoadedCallback();
			}
			return;
		}
		const transformedData = [];
		const units = [];

		sensorData.forEach(sensor => {
			const sensorOption = sensorView.find(opt => parseInt(opt.sensorid) === sensor.sensorid);
			if (sensorOption) {
				const formattedSensorData = {
					id: sensorOption.name,
					unit: sensorOption.unit,
					street: sensorOption.street,
					name: sensorOption.name,
					data: [],
				};
				const numberWithSameName = sensorView.filter(opt => opt.name === formattedSensorData.id).length;
				if (numberWithSameName > 1) {
					const numberOfUniqueStreets = sensorView
						.filter(opt => opt.name === formattedSensorData.name)
						.map(opt => opt.street)
						.filter((value, index, self) => self.indexOf(value) === index).length;

					if (numberOfUniqueStreets > 1) {
						formattedSensorData.id = `${prettyPrintStreet(sensorOption.street)} - ${formattedSensorData.id}`;
					}
					if (numberOfUniqueStreets != numberWithSameName) {
						let availableIndex = 1;
						while (true) {
							const temporal_id = `${formattedSensorData.id} (${availableIndex})`;
							const sensorWithSameName = transformedData.find(opt => opt.id === temporal_id);
							if (sensorWithSameName === undefined) {
								formattedSensorData.id = `${formattedSensorData.id} (${availableIndex})`;
								break;
							}
							availableIndex++;
						}
					}
				}

				let dataPoints = [];
				if (normalize) {
					const max = Math.max(...sensor.sensordata.map(s => s.y));
					const min = Math.min(...sensor.sensordata.map(s => s.y));
					dataPoints = sensor.sensordata.map(dataPoint => ({
						x: dataPoint.x,
						y: max === min ? 0 : (dataPoint.y - min) / (max - min),
						yLabel: dataPoint.y === null ? null : dataPoint.y * (sensorOption.multiplier || 1),
					}));
				} else {
					dataPoints = sensor.sensordata.map((dataPoint, idx) => ({
						x: dataPoint.x,
						y: dataPoint.y === null ? null : dataPoint.y * (sensorOption.multiplier || 1),
						yLabel: dataPoint.y === null ? null : dataPoint.y * (sensorOption.multiplier || 1),
					}));
				}
				for (const dataPoint of dataPoints) {
					if (dataPoint.y !== null && dataPoint.yLabel !== null) {
						formattedSensorData.data.push({
							x: new Date(dataPoint.x).getTime(),
							y: dataPoint.y,
							yLabel: dataPoint.yLabel,
							unit: sensorOption.unit,
						});
					}
				}

				transformedData.push(formattedSensorData);
				units.push(sensorOption.unit || '');
				//free up all let variables
				dataPoints = [];
			}
		});

		if (dataPrevious && previousTimeToggle) {
			const sensorData = dataPrevious.getSensorData.slice(0, dataPrevious.getSensorData.length);

			sensorData.forEach(sensor => {
				const sensorOption = sensorView.find(opt => parseInt(opt.sensorid) === sensor.sensorid);
				if (sensorOption) {
					const sensorName = t('modalSettings.previous') + ` - ${sensorOption.name}`;
					const sensorWithSameName = transformedData.find(opt => opt.id === sensorName);
					const formattedSensorData = {
						id: sensorName,
						unit: sensorOption.unit,
						street: sensorOption.street,
						data: [],
					};
					if (sensorWithSameName !== undefined) {
						formattedSensorData.name = t('modalSettings.previous') + ` ${sensorOption.street} - ${sensorOption.name}`;
						sensorWithSameName.id = t('modalSettings.previous') + ` ${sensorWithSameName.street} - ${sensorWithSameName.id}`;
					}

					let dataPoints = [];
					if (normalize) {
						const max = Math.max(...sensor.sensordata.map(s => s.y));
						const min = Math.min(...sensor.sensordata.map(s => s.y));
						dataPoints = sensor.sensordata.map(dataPoint => ({
							x: dataPoint.x,
							y: max === min ? 0 : (dataPoint.y - min) / (max - min),
							yLabel: dataPoint.y === null ? null : dataPoint.y * (sensorOption.multiplier || 1),
						}));
					} else {
						dataPoints = sensor.sensordata.map((dataPoint, idx) => ({
							x: dataPoint.x,
							y: dataPoint.y ? dataPoint.y * (sensorOption.multiplier || 1) : null,
							yLabel: dataPoint.y === null ? null : dataPoint.y * (sensorOption.multiplier || 1),
						}));
					}

					let previousValidDataPoint = null;
					for (const dataPoint of dataPoints) {
						let correctedDate = new Date(dataPoint.x);
						if (timeRange.offset === MINUTES_PER_MONTH) {
							correctedDate.setFullYear(correctedDate.getFullYear() + 1);
						} else if (timeRange.offset === MINUTES_PER_YEAR && !monthLastYearBool) {
							correctedDate.setMonth(correctedDate.getMonth() + 1);
							if (correctedDate.getMonth() === 12) {
								correctedDate.setMonth(0);
							}
						} else if (timeRange.offset === MINUTES_PER_YEAR && monthLastYearBool) {
							correctedDate.setFullYear(correctedDate.getFullYear() + 1);
						}

						if (
							previousValidDataPoint === null ||
							new Date(correctedDate).getTime() > new Date(previousValidDataPoint).getTime()
						) {
							if (dataPoint.y !== null && dataPoint.yLabel !== null) {
								formattedSensorData.data.push({
									x: new Date(correctedDate).getTime(),
									y: dataPoint.y,
									yLabel: dataPoint.yLabel,
									unit: sensorOption.unit,
								});
								previousValidDataPoint = correctedDate;
							}
						}
					}
					transformedData.push(formattedSensorData);
				}
			});
		}

		setChartData(transformedData);
		if (dataLoadedCallback) {
			dataLoadedCallback();
		}
		if (sensorView.length) setSensorMetaData(sensorView);

		setSensorUnits(units);
	}
	return children(chartData, sensorUnits, dataLoading, sensorMetaData);
}

export { GET_SENSOR_DATA_AND_VIEW, getIntervalFromDates, GET_SENSOR_DATA };
export default SensorDataFetcher;

import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import { LinearProgress, FormControlLabel, Switch, FormGroup, Divider } from '@material-ui/core';
import ZoomOutMapOutlinedIcon from '@material-ui/icons/ZoomOutMapOutlined';
import CloseIcon from '@material-ui/icons/Close';
import ModalTimeSwitches from './ModalTimeSwitches';
import ModalSensorButton from './ModalSensorButton';

import SensorDataFetcher from '../Widget/SensorDataFetcher';
import LineChart from '../Widget/LineChart';
import BarChart from '../Widget/BarChart';
import PieChart from '../Widget/PieChart';
import TimeSelectButton from './TimeSelectButton';
import DateSlider from './DateSlider';
import ChangeTitle from './ChangeTitle';
import * as colors from '../../../colors';
import { useTranslation } from 'react-i18next';
import { CHART_WIDGET_TYPES, MINUTES_PER_WEEK, MINUTES_PER_YEAR } from '../../../constants';

const SENSOR_DATA_WIDTH = 24;
const MILLISECONDS_PER_MINUTE = 1000 * 60;

const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '-50px',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: '90%',
		height: '85%',
		paddingTop: '10px',
	},
	btnpop: {
		position: 'absolute',
		top: 0,
		right: 40,
		zIndex: 100,
	},
	divider: {
		position: 'absolute',
		top: '8%',
		left: '80%',
		orientation: 'vertical',
		height: '62%',
		width: '1.2px',
		color: 'white',
	},
	btninside: {
		'& > *': {
			margin: theme.spacing(1),
		},
		position: 'absolute',
		top: '7%',
		right: '4.8%',
		zIndex: 100,
	},
	switchgroup: {
		color: 'white',
		width: '100%',
		fontFamily:
			'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica Neue, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
	},
	switch: {
		position: 'absolute',
		left: '26%',
		top: '25%',
		color: 'white',
		letterSpacing: '0px',
		textTransform: 'uppercase',
		fontFamily:
			'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica Neue, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
	},
}));

const filterData = (chartData, startEndIndex) => {
	const newData = chartData.reduce((filtered, value) => {
		return [
			...filtered,
			{
				...value,
				data: value.data.slice(
					value.data.findIndex(datapoints => datapoints.x >= startEndIndex[0]),
					value.data.findLastIndex(datapoints => {
						return datapoints.x <= startEndIndex[1];
					}) + 1
				),
			},
		];
	}, []);
	return newData;
};

function getInitialStartEndIndex(timerange) {
	const roundTimestamp = (ts, floor = true) => {
		const date = new Date(ts);
		date.setHours(0, 0, 0, 0);
		if (!floor) {
			date.setDate(date.getDate() + 1);
			date.setMilliseconds(-1);
		}
		return date.getTime();
	};
	if (timerange.offset === undefined || timerange.offset === null) {
		return [roundTimestamp(new Date(timerange.startDate).getTime()), roundTimestamp(new Date(timerange.endDate).getTime(), false)];
	}
	const nowUnix = new Date().getTime();
	return [roundTimestamp(nowUnix - timerange.offset * 60 * 1000), roundTimestamp(nowUnix, false)];
}

function getDatapointsFromTimeRange(timerange, max = 0) {
	const interval = (timerange.endDate - timerange.startDate) / MILLISECONDS_PER_MINUTE;

	let numberOfDatapoints = 12;
	if (interval < MINUTES_PER_WEEK) {
		numberOfDatapoints = 24;
	} else if (interval < MINUTES_PER_YEAR) {
		numberOfDatapoints = interval / (24 * 60);
	}

	if (max) {
		numberOfDatapoints = Math.min(max, numberOfDatapoints);
	}
	return numberOfDatapoints;
}

const datapointMap = {
	[CHART_WIDGET_TYPES.LineChart]: timerange => getDatapointsFromTimeRange(timerange),
	[CHART_WIDGET_TYPES.PieChart]: timerange => getDatapointsFromTimeRange(timerange),
	[CHART_WIDGET_TYPES.BarChart]: timerange => getDatapointsFromTimeRange(timerange),
	[CHART_WIDGET_TYPES.SpeedoMeter]: () => 24,
};

const ModalboxSettingsLineGraph = props => {
	const classes = useStyles();

	const [normalize, setNormalize] = useState(props.normalize);
	const [open, setOpen] = useState(false);
	const [popoutSensors, setPopoutSensors] = useState([]);
	const [title, setTitle] = useState(props.title);
	const [startEndIndex, setStartEndIndex] = useState([0, 0]);
	const [previousTimeToggle, setPreviousTimeToggle] = useState(false);
	const [monthLastYearBool, setMonthLastYearBool] = useState(false);

	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleOpen = () => {
		setPopoutSensors(props.popoutSensors);
		setStartEndIndex(getInitialStartEndIndex(props.currentDatetimeRange));
		setNormalize(props.normalize);
		setOpen(true);
	};

	const handlePreviousTimeToggle = () => {
		if (previousTimeToggle === false) {
			setPreviousTimeToggle(true);
		} else {
			setPreviousTimeToggle(false);
		}
	};
	const handleToggleLastYearData = () => {
		if (monthLastYearBool === false) {
			setMonthLastYearBool(true);
		} else {
			setMonthLastYearBool(false);
		}
	};

	const handleClose = () => {
		props.updateChildTitle(title, props.wid);
		setOpen(false);
	};

	const changeTitleCallback = title => {
		setTitle(title);
	};

	const startEndCallback = index => {
		setStartEndIndex(index);
	};

	const updateNormalizeCallback = newValue => {
		setNormalize(newValue);
	};

	if (!isMounted.current) {
		return null;
	}
	return (
		<div>
			{props.hidePopoutIcon ? undefined : (
				<div className={classes.btnpop} data-cy='modal-pop-up'>
					<IconButton style={{ position: 'absolute', top: 0, left: -10 }} onClick={handleOpen} aria-label='open'>
						<ZoomOutMapOutlinedIcon />
					</IconButton>
				</div>
			)}
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				data-cy='modal-settings-line-graph'
			>
				<Fade in={open}>
					<div id='main' className={classes.paper}>
						<ChangeTitle title={title} changeTitleCallback={changeTitleCallback} />
						<div className={classes.btninside}>
							<IconButton onClick={handleClose} aria-label='delete'>
								<CloseIcon />
							</IconButton>
						</div>
						<div style={{ width: '100%', height: '90%', zIndex: 0, marginTop: '50px' }}>
							<SensorDataFetcher
								sensors={popoutSensors}
								numberOfDatapoints={datapointMap[props.widgetType](props.currentDatetimeRange)}
								timeRange={props.currentDatetimeRange}
								updateInterval={props.currentAutoUpdateInterval}
								normalize={normalize}
								monthLastYearBool={monthLastYearBool}
								previousTimeToggle={previousTimeToggle}
								widgetType={props.widgetType}
							>
								{(chartData, sensorUnits, loading, barChartKeys) => {
									return (
										<>
											{loading ? (
												<LinearProgress
													style={{ position: 'absolute', width: '65.6%', zIndex: 1, marginTop: '30px' }}
												/>
											) : undefined}
											{props.widgetType === 'LineChart' ? (
												<LineChart
													data={filterData(chartData, startEndIndex)}
													units={sensorUnits}
													showTitle={false}
													timeRange={props.currentDatetimeRange}
													popout={true}
												/>
											) : props.widgetType === 'PieChart' ? (
												<PieChart
													data={filterData(chartData, startEndIndex)}
													units={sensorUnits}
													showTitle={false}
													timeRange={props.currentDatetimeRange}
													popout={true}
												/>
											) : (
												<BarChart
													data={filterData(chartData, startEndIndex)}
													units={sensorUnits}
													showTitle={false}
													timeRange={props.currentDatetimeRange}
													popout={true}
												/>
											)}
											{chartData.length ? (
												<DateSlider
													chartData={chartData}
													dataIndex={startEndIndex}
													dataIndexCallback={startEndCallback}
													initialStartEndIndex={getInitialStartEndIndex(props.currentDatetimeRange)}
													timeRange={props.currentDatetimeRange}
												/>
											) : undefined}
											<TimeSelectButton
												dataIndex={startEndIndex}
												dataIndexCallback={startEndCallback}
												constraintTimestamp={getInitialStartEndIndex(props.currentDatetimeRange)}
											/>
										</>
									);
								}}
							</SensorDataFetcher>
						</div>
						<SettingsLineGraphModal
							selectedProperties={props.selectedProperties}
							popoutSensors={popoutSensors}
							normalize={normalize}
							changeSensorsCallback={sensors => setPopoutSensors(sensors)}
							normalizeCallback={updateNormalizeCallback}
							handlePreviousTimeToggle={handlePreviousTimeToggle}
							handleToggleLastYearData={handleToggleLastYearData}
							monthLastYearBool={monthLastYearBool}
							previousTimeToggle={previousTimeToggle}
							currentDatetimeRange={props.currentDatetimeRange}
						/>
					</div>
				</Fade>
			</Modal>
		</div>
	);
};

function SettingsLineGraphModal({
	selectedProperties,
	popoutSensors,
	normalize,
	changeSensorsCallback,
	normalizeCallback,
	handlePreviousTimeToggle,
	handleToggleLastYearData,
	previousTimeToggle,
	monthLastYearBool,
	currentDatetimeRange,
}) {
	const classes = useStyles();

	const { t } = useTranslation();

	const toggleNormalizeCallback = event => {
		normalizeCallback(event.target.checked);
	};

	return (
		<div
			style={{
				backgroundColor: colors.primary,
				position: 'absolute',
				left: '30%',
				top: '8%',
				display: 'flex',
				flexDirection: 'row',
				width: '36%',
				height: '6%',
				border: '1px solid lightgrey',
				borderRadius: '5px',
			}}
		>
			<ModalSensorButton
				selectedProperties={selectedProperties}
				graphSensors={popoutSensors}
				changeSensorsCallback={changeSensorsCallback}
				data-cy='modal-sensor-button'
			/>
			{/* <Divider variant='vertcal' style={{ marginBottom: '20px', backgroundColor: 'white', marginTop: '5px' }} /> */}

			<FormGroup data-cy='sensor-selector-table'>
				<FormControlLabel
					className={classes.switch}
					control={
						<Switch
							checked={normalize}
							onChange={toggleNormalizeCallback}
							name='normalize'
							color={'default'}
							data-cy='inner-switch'
							size='small'
						/>
					}
					label={<span style={{ fontSize: '12px' }}>{t('toolbar.normalize')}</span>}
					labelPlacement='end'
					data-cy='normalize-switch'
				/>
			</FormGroup>

			{/* <Divider variant='middle' style={{ marginBottom: '15px', backgroundColor: 'white' }} /> */}

			<ModalTimeSwitches
				handlePreviousTimeToggle={handlePreviousTimeToggle}
				handleToggleLastYearData={handleToggleLastYearData}
				monthLastYearBool={monthLastYearBool}
				previousTimeToggle={previousTimeToggle}
				currentDatetimeRange={currentDatetimeRange}
			/>
		</div>
	);
}

export { SENSOR_DATA_WIDTH, SettingsLineGraphModal };
export default ModalboxSettingsLineGraph;
